<template>
  <b10-base>
    <ValidationProvider
      v-slot="{ errors }"
      name="descripcion"
      :rules="formRules.descripcion"
    >
      <v-text-field
        v-model="form.descripcion"
        clearable
        flat
        label="Descripción"
        :error-messages="errors"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="descripcion"
      :rules="formRules.unidaes"
    >
      <v-text-field
        v-model.number="form.unidades"
        clearable
        flat
        label="Unidades"
        type="number"
        :error-messages="errors"
      />
    </ValidationProvider>
    <b10-textarea
      v-model="form.observacion"
      label="Observaciones"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  props: {
    id: {
      type: [Number, String],
      default: '',
    },
  },
  data () {
    return {
      form: {
        descripcion: null,
        unidades: null,
        observacion: null,
      },
      formRules: {
        descripcion: { required: true },
        unidades: { required: true },
      },
    }
  },
  async created () {
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      const formData = await this.$offline.lordenTrabajoDetalle.row(this.id)
      this.$set(this.form, 'descripcion', formData.dummy.descripcion)
      this.$set(this.form, 'unidades', formData.dummy.unidades)
      this.$set(this.form, 'observacion', formData.dummy.observacion)
    }
  }
}
</script>
